<template lang="html">
  <div class="container-fluid" style="min-height: 100vh">
    <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSfUes3bfgbMr3cbyLDXPuaT_PaE3Bd1Rt-lBBTfEq_x0A0Guw/viewform?embedded=true"
        style="min-width: 100%; border: none; min-height: 100vh"
        height="1100px"
        width="100%"
        scrolling="yes"
        class="feedback"
        id="iframe"
        frameborder="0" marginheight="0" marginwidth="0">Loading…
    </iframe>
  </div>
</template>

<script>
import iFrameResize from 'iframe-resizer/js/iframeResizer'

export default {
  name: 'component name',

  data () {
    return {
      source: ''
    }
  },

  methods: {
    iframeLoaded () {
      console.log('run')
      iFrameResize({log: true}, '#iframe')
    }
  }
}
</script>

<style lang="scss">
.feedback{
margin: auto;
  width: 100%;
  height: 100%;
}
</style>